/* NavBar.module.css */
.navbar {
    position: fixed;
    top:0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    /*justify-content: space-around;*/
    align-items: center;
    border-bottom: 1px solid #3b3a3a;
    
  }

  .contentimage {
    width: 40%;
    
  }
  
  .section {
    display: flex;
    justify-content:space-around;
    width: 50%;
    flex-wrap: wrap;
    font-size: 20px;
    padding:10px
  }
  
  .sesion {
    display: flex;
    justify-content:space-around;
    width: 5%;
    flex-wrap: wrap;
    font-size: 20px;
    padding:0 10px 0 0;
  }
  .link {
    text-decoration: none;
    color: rgb(0, 26, 255);
    font-weight: 500;
  }
 
  /* Estilos para las imágenes dentro de los botones */
   
  .contentimage img{
    max-width: 5rem; /* Tamaño adecuado para las imágenes en rem */
   padding-left: 10%;
   cursor: pointer;
  }


 
  .idioms img{
    width: 16px;
    height: 22px;
    padding-top: 20px;
  }


  .check{
    position:relative;
    width: 40px;
    height: 20px;
    margin-right: 0.5rem;
    cursor: pointer;
    padding-bottom: -10px;
  }

  .check:before{
    content: '';
    position:absolute;
    width: 40px;
    height: 20px;
    background: #333;
    border-radius: 25px;
    
  }

  .check:after{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 25px;
    transition: 0.25s;
    border: 2px solid #333;
    box-sizing: border-box;
    
  }

  .check:checked:after{
    left:20px;
    border: 2px solid #edb40b;
    
  }

  .check:checked:before{
    background: #edb40b;
 
  }

  .photo__container {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 10px;
    cursor: pointer;
    border: 2px solid var(--second-color);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-out;
  }
  .photo__container:hover {
    transform: scale(1.1);
  }
  
  .photo__container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Esto asegura que la imagen se ajuste manteniendo su relación de aspecto */
    cursor: pointer;
  }
  
  .user__options {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 80px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
    z-index: 1;
  }
  
  .user__options p {
    margin: 5px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .user__options button {
    background-color: #edb40b;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin: 5px;
    width: 120px;
  }
  
  .user__options button:hover {
    background-color: #856505;
  }

  .idioms {
    min-width: 10%;
  
  }

  .checkbtn {
    font-size: 30px;
    color: rgb(0, 26, 255);
    cursor: pointer;
    display: none;
  
    
  }

  .hamburguer {
    padding:0;
    margin:0;
    
  }

  @media (max-width: 952px) {
    .contentimage img{
      padding-left: 20px;
    }
    .section {
      font-size: 14px;
      
    }
    .sesion {
      font-size: 14px;
      
    }
    .contentimage {
      width: 30%;
      
    }

  }

  @media (max-width: 858px) {
    .checkbtn{
      display:block;
      margin-left: 50px;
    }
    .section{
      flex-direction: column;
      position: fixed;
      width: 40%;
      height: 30vh;
      background: #edb40b;
      top: 90px;
      left: -120%;
      text-align:center;
      transition: all .5s;
      font-size: 20px;
    }

    .sesion {
      width: 25%;
      font-size: 16px;
    }
    .contentimage {
      width: 30%;
    }
   
    .idioms {
      width: 30%
    }
      
    .hamburguer {
      margin:5%;
    }
  }
  