.actividad {
    align-items: center;
    justify-content: center;
    /*margin: 0 auto;*/
    margin-top: 120px;
    height: auto;
}

.title{
    text-align:center;
    color: #333;
    margin-bottom: 0;
    font-size: 40px;
}


.tarjetas {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px;
   /*background: linear-gradient(to bottom, rgba(0, 255, 255, 1) 10%, rgba(0, 0, 255, 0) 100%);*/
}

/*.tarjetas img{
    width: 300px;
    max-height: 300px;
}*/

.card {
    position: relative;
    border: 1px solid #ccc;
    padding: 1vh;
    margin: 2vh;
    /*text-align: center;*/
    background-color: #faf7f7;
    border-radius: 1vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    width: 40vh;
    height: 50vh;
    /*overflow: hidden; /* Añadido para ocultar el desbordamiento de la descripción */
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card a {
    text-decoration: none;
    color: inherit;
  }
  
  .imagen {
    width: 40vh;
    height: 40vh;
   
    /*z-index: 0; /* Coloca la imagen detrás del contenido */
  }
  

  .descripcion {
    margin: 0;
    padding: 1vh;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.656), rgba(255, 255, 255, 0.5)); /* Gradiente transparente hacia semi-transparente */
    /*position: absolute;*/
    max-height: 10%;
    width: 92.5%;
    min-height: 10%;
    z-index: 1;
    bottom: 0;
    border-bottom-left-radius: .5vh;
    border-bottom-right-radius: .5vh;
    backdrop-filter: blur(10px); /* Ajustado para un efecto de difuminado más pronunciado */
   
  }
  
  
  .name {
    /*font-family: 'Roboto', sans-serif;*/
    font-size: 30px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    align-items: center; /* Centra verticalmente */
    height: 100%;
    margin-left: .5rem;
    margin-right: .5rem;
    margin-top: -1.5rem;
  }
  
  .info {
    color: #20873f;
    font-weight: bold;
    margin-top: 8vh;
    margin-bottom: -0.3rem;
    
    
  }
  
