
.contact {
    margin-top: 90px;
    display:flex;
    flex-wrap: wrap;
    color:#fff;
    background-color: #333; /* Light mode background color*/
    transition: background-color 0.3s, color 0.3s;
    padding-bottom: 50px; 

}



.program {
    margin: 10px auto 10px auto;
    padding: 30px;
    width:500px;
    /*border: 1px solid #3b3a3a;*/
    /*background-color: #faf7f7;*/
    background-color:  #333;
    border-radius: 8px;
}

.program h1,p{
    margin-left:4%;

}

.program p{
    font-size: 20px;
}

.content {
    width: 700px;
    margin: 15px auto 15px auto;
    background-color: #333;
    display:flex;
    justify-items:center;
    flex-direction:column;
    align-items: center;

    
}

.frame {
    border:0;
    width:90%;
    height:50%; 
    margin-left:4%;
    border-radius: 8px;
}

.content h2{
    font-size: 25px;
    
}

.content form {
    width: 80%;
    margin: 10px;
 
}

.content input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 15px;
}

.content textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 3px;
    height: 100px;
}

.content button {
    border-radius: 3px;
    background-color:#edb40b;
    margin-top: 10px;
    color: #fff;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    
}

.content button:hover {
    background-color: #856505;
}

.contenedor {
    /*background-color:#20873f;*/
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -10px;
  
}


.contenedor p{
    text-wrap: wrap;
    font-size: 20px;
}

