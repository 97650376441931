

.card {
    
    align-items: center;
    justify-content: center;
    margin: 1% 37%;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position:fixed;
    border: 1px solid #ccc;
    padding: 1vh;
    /*margin: 0;   
    /*text-align: center;*/
    background-color: hsl(30, 87%, 73%);
    border-radius: 1vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    width: 300px;
    height: 430px;
    /*overflow: hidden; /* Añadido para ocultar el desbordamiento de la descripción */
    z-index:1001;
  }
  
  .card:hover {
    transform: scale(1.10);
  }
  

  
  .imagen {
    width: 100%;
    /*max-height: 65vh;
   
    /*z-index: 0; /* Coloca la imagen detrás del contenido */
  }
  

  .descripcion {
    margin: 0;
    padding: 1vh;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.656), rgba(255, 255, 255, 0.5)); /* Gradiente transparente hacia semi-transparente */
    /*position: absolute;*/
    height: 6%;
    width: 96%;
    /*min-height: 10%;*/
    z-index: 1;
    bottom: 0;
    border-bottom-left-radius: .5vh;
    border-bottom-right-radius: .5vh;
    backdrop-filter: blur(10px); /* Ajustado para un efecto de difuminado más pronunciado*/
   
  }
  
  
  .name {
    color:rgb(0, 26, 255);
    /*font-family: 'Roboto', sans-serif;*/
    font-size: 25px;
    /*position: absolute;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    align-items: center; /* Centra verticalmente */
    /*height: 50%;*/
    margin-left: .5rem;
    margin-right: .5rem;
    /*margin-top: -1.5rem;*/
    margin-top: -3%;
  }

  .nametitle {
    color:rgb(0, 26, 255);
    /*font-family: 'Roboto', sans-serif;*/
    font-size: 18px;
    margin-top: -4%;
  }

  .content {
    color:#5f5e5e;
    font-size: 15px;
    /*position: absolute;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    align-items: center; /* Centra verticalmente */
    height: 50%;
    margin-left: .5rem;
    margin-right: .5rem;
    /*margin-top: -1.5rem;*/
    margin-top: -2%;
  }

  .closer {
    margin-left: 90%;
    cursor: pointer;
    font-size: 20px;
   background-color: hsl(30, 87%, 73%);;
   color:#333;
  }
  
  .closer:hover {
    background-color: #ccc9c9;
    border-radius: 100%;
  }
  
  .disabled-background {
    pointer-events: none; /* Desactiva los eventos en el fondo */
    opacity: 0.5; /* Oscurece el fondo */
}

@media (max-width: 858px) {
  .card{
      margin: 10% 12%;
  }
}