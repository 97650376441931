.present {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
   
    
}
.title {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 50%;
    display: flex;
    
}

.title img{
    width: 40%;
}

.title p{
    text-align:center;
    color: #333;
    font-size: 40px;
    
}

.paragrah {
    display:flex;
    justify-content:space-around;
    flex-wrap: wrap;
    margin:10px;
    padding: 50px;
    background-color: #faf7f7;
}


.group {
    text-wrap: wrap;
    height: 200px;
    background-color:#fff;
    align-items: center;
    padding: 10px;
    margin-top: 30px;
}

.group p, h2{
    text-align: center;
    color: #333;
    margin:0;
    font-size: 25px;
    margin-top: 20px;
    
}

.component {
    text-wrap: wrap;
    width: 360px;
    /*height: 300px;*/
    align-items: center;
    margin:10px auto;
}

.component img{
    width: 25%;
    border-radius: 50%;
    background-color:#fff;
    padding: 5%;
    margin: auto;
    display: block;
}
