.actividad {
    margin-top: 90px;
    align-items: center;
    justify-content: center;
    background-size:100% 100%;
    width: auto;
    height: auto;
    padding: 2% 1% 2%;
    background: linear-gradient(to bottom, rgba(0, 255, 255, 1) 10%, rgba(0, 0, 255, 0) 100%);
}

.actividad h1{
    text-align:center;
    color: #333;
    font-size: 50px;
    color: #333;
    margin-top: 5px;
}

.imagen {
    width: 50vh;
    max-height: 50vh;
    
   
    /*z-index: 0; /* Coloca la imagen detrás del contenido */
  }

  .card {
    display: flex;
    flex-wrap:wrap;
  }

  .info {
    font-size: 30px;
  }

  .descripcion {
    
    border-radius: 10%;
    width: 900px;
    height: auto;
    background-color:#fff;
    border: 2px solid #edb40b;
     /* Ajusta la transparencia */
     text-justify: auto;
     
  }

  .descripcion p{
    font-size: 38px;
    color:#333;
    text-align: justify;
    padding-right: 30px;
    font-style: italic;
    text-wrap: wrap;
    
  }

.descripcion img{
    width:100px;
    margin-left: 80%;
    margin-top: -20%;
    opacity: 0.5;
}

.botones button {
  background-color: #edb40b;
  font-size: 20px;
  color:#fff;
  cursor:pointer;
  margin: 0 50px 20px;
  
}
.botones button:hover {
  background-color: #856505; 
    
}

.botones {
  margin-top: 30px;
  text-align:center;
}


@media (max-width: 858px) {
  .actividad h1{
    font-size: 30px;
  }

  .imagen {
    width: 20vh;
    max-height: 20vh;
    margin: 0 auto;
  }

  .descripcion {
    width: 380px;
    height: auto;
    margin: auto;
  }

  .descripcion img{
    width:50px;
  }

  .descripcion p{
    font-size: 24px;
  }
}