.form__container {
    max-width: 800px;
    margin: 50px auto; 
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 130px;
  
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .form .close {
    margin-left: 92%;
    cursor: pointer;
    color: #333;
    background-color: #fff;
  }

  .form .close:hover {
    background-color: #ccc9c9;
    border-radius: 50%;
  }
  
  .s-form {
    margin-top: 5px;
  }
  
  fieldset {
    border: none;
  }
  
  legend {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  
  label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  .form__container textarea {
    width: 100%;
    height: 80px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form__container p {
    font-size: 0.8rem;
    color: #d9534f; /* / Color de error / */
    margin-top: 5px;
    margin-bottom: 15px;
  }
  
  span {
    font-size: 0.8rem;
    color: #777;
  }
  
  .btnC {
    background-color: #edb40b; /* / Color de fondo del botón / */
    color: #fff; /* / Color del texto del botón / */
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-left: 40%;
    border-radius: 8px;
    font-size: 15px;
  }
  
  button:hover {
    background-color: #856505; /* / Color de fondo del botón al pasar el ratón  */
  }

  select {
    margin-bottom: 100px;
  }