.container {
  display: flex;
  justify-content: center;
  background-image: url('../../assets/dron\ 39.jpg');
  background-repeat: no-repeat;
  background-size:100% 100%;
  padding: 20px;
  margin-top: 90px;
}


  

.s-formedit  {
  width: auto;
  padding:  20px;
  margin: 20px;
  background-color: #fff; /* Light mode background color */
  color: #333; /* Light mode text color */
  transition: background-color 0.2s, color 0.3s;
  border: 1px solid #3b3a3a;
  /*box-shadow: 5px 5px 20px rgb(132, 132, 132);*/
  border-radius: 5px;
}
  .s-formedit fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }
  
  .s-formedit label {
    display: block;
    margin-bottom: 0px;
  }
  
  .s-formedit select {
    margin-top: 15px;
  }
  .s-formedit h3 {
    display: block;
    margin-bottom: 10px;
  } 

  .s-formedit input {
    width: 300px;
    padding: 8px;
    margin: 15px 30px 15px 0px;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: #fff;
    /*color: #fff;*/
    color: black;
    
  }

  .s-formedit .inputfile {
    display: none;
    
  }
  
  .s-formedit .buttonfile {
    margin: 0;
    width: 30%;
    border: 1px solid var(--second-color);
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px; /* Ajusta el espacio entre el campo de archivo y los demás elementos */
    background-color: #edb40b;
    cursor: pointer;
    padding: 5px;
    color: #fff;
  }

  .s-formedit .buttonfile:hover {
    background-color: #856505;
  }

  .s-formedit .buttonsub {
    width: auto;
    padding: 10px;
    background-color: #edb40b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 20%;
    max-height: 40px;
    margin-top: 20px;
  }
  
  .s-formedit .buttonsub:hover {
    background-color: #856505;
  }
  
  .s-formedit p {
    margin-top: -10px;
    font-size: 12px;
    color: red;
  }
  
  
 
 
 .s-formedit img {
    max-width: 80px;
    max-height: 100px;
    border-radius: 50%;
    text-align: center;
  }


  .s-formedit .close {
    margin-left: 95%;
    cursor: pointer;
    color:#333;
    background-color: #fff;
  }

  .s-formedit .close:hover {
    border-radius: 50%;
    background-color:#ccc9c9;
  }

  .s-formedit .dataperfil {
    display: flex;
    flex-wrap: wrap;
    
  }
  
  
  .s-formedit .select {
    width: 380px;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: #fff;
    /*color: #fff;*/
    color: black;
    font-size: 12px;
  }

  .s-formedit .dataname {

    width: 400px;

  }

  .s-formedit .divremove__btn{
    max-width: 380px;
    
  }

  .s-formedit .divremove__btn .removebtn {
    margin: 10px 0;
    height: 30px;
    width: auto;
    padding: 10px;
    background-color: #edb40b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }

  .s-formedit .divremove__btn .removebtn:hover {
    background-color: #856505;
  }

 .s-formedit .envio{
    display: flex;
    align-items: center;
    margin-top:30px;
  }
  
  .s-formedit .envio .checkbox{
    margin-left: -140px;
    margin-right: -130px;
  }

  .s-formedit .envio .mensaje{
    font-size:small;
    font-weight: bold;
    margin-right: -150px;
    margin-top: 20px;
    max-width: 80%;
    text-wrap: wrap;
  }

  
@media (max-width: 858px) {
  .s-formedit  {
    padding: 20px 0px 20px 20px;
  }
  .s-formedit .close {
    margin-left: 90%;
  }
}
  /*.s-formedit .final {
    display: flex;
  
  }*/

 