
.photos {
    padding: 20px;
    background-color:  #333;
    text-align: center;
    width: auto;
    height: 100%;
    margin-top: 90px;
}
.contentpili {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    margin:10px;
    
}

.contentlibro {  
    background-color: #333;
    padding: 20px;
    text-align: center;
    margin-top: 90px;
}

.libro {
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
}
.libro img {
    width: 400px;
    /*border-radius: 8px;*/

    
}

.contentlibro button{
    color:#fff;
    font-size: 30px;
    cursor: pointer;
    background-color: #333;
    margin: 20px;
}
.contentlibro button:hover{
    background-color: black; 
    color:#fff;
    border-radius: 50%;
    height: 50px;
}


.contentpili img{
    width: 400px;
    /*border-radius: 8px;*/
    margin:30px;
    
}


.photos button{
    color:#fff;
    font-size: 30px;
    margin: 20px;
    cursor: pointer;
    background-color: #333;
    
}

.photos button:hover {
    background-color: black; 
    color:#fff;
    border-radius: 50%;
    height: 50px;
}

.containText {
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 400px; 
}


.containText p {
    color: #fff;
    text-align: justify;
    font-size: 30px;

}
.containText label{
    font-size: 10px;
}

.librohistoria {
    margin-top: 40px;
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
}
.librohistoria img {
    width: 400px;
    
}