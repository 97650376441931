.btn{
    
    justify-items: auto;
    padding: 5px 20px;
    cursor: pointer;
}

.img{
    width: 15px;
    margin-left: 0px;
    margin-right: 25px;
}
