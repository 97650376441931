
.tablecontainer {
  margin-top: 120px;
  
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

thead {
  background-color: #f2f2f2;
  color: black;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

/* Estilo para filas deshabilitadas (banned) */
.disabled {
  background-color: #9e6612; /* Puedes cambiar el color según tus preferencias */
  color: #f2f2f2;
}
