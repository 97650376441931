/* footer.module.css */

.container {
    background-color: #333;
    color: #fff;
    padding: 10px;
    margin: 0;
    position:static
    
  }
  
  
  
  
  .copyright {
    display: flex;
    flex-wrap: wrap;
    text-align:center;
    justify-content:space-around;
  }
  
  .copyright .redes {
    width: 300px;
  }
 
  