
.botones button {
  background-color: #edb40b;
  font-size: 20px;
  color:#fff;
  cursor:pointer;
  margin: 0 50px 20px;
  
}
.botones button:hover {
  background-color: #856505; 
    
}

.botones {
  text-align:center;
}