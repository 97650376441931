.about {
    width: auto;
    padding-bottom: 20px; 
    margin-top: 100px;
}

.container {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 90%;
}


.container h1{
    text-align:center;
    color: #333;
    
}

.container p{
    text-align: justify;
    

}


.presentation {
    max-width: 80%;
    margin: 0 0 0 10%;
    display: flex;
    flex-wrap:wrap;

}
.presentation h3{
    color: #333;
}

.pili,.luisma {
    width:500px;
    margin: 0 auto;
    text-align: center;
}

.pili img{
   
    width:180px;
    height: 180px;
    padding: 0 10px 0 40px;
    /*border-radius: 50%;*/
}

.pili .libro{
    width:50px;
    height: 50px;

}
.pili .libro:hover {
    transform: scale(1.15);
  }

.luisma img {
    
    width:180px;
    height: 180px;
    padding: 0 40px 0 40px;
    /*border-radius: 50%;*/
}


