.contemp {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 90%;
    padding: 30px;
    
}

.aliadas {
    text-align:center;
    color: #333;
    margin-bottom: 0;
    font-size: 40px;
}

.industria {
    text-align: center;
    color: #333;
    margin:0;
    font-size: 25px;
}



.empresas {
    display:flex;
    justify-content:space-around;
    flex-wrap: wrap;
    max-width: 90%;
    margin:20px;
    padding: 40px;
}
.empresas img{
   max-width: 200px;
    
}