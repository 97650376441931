.capsulecontainer{
  margin-top:90px;

}

.disabled {
    color: gray;
    text-decoration: line-through;
  }

  h2 {
    margin: 20px;
  }

  .link {
    text-decoration: none;
    margin: 20px;
    color: rgb(0, 26, 255);
    font-weight: bold;
  }

  button {
      background-color: #edb40b; /* / Color de fondo del botón / */
      color: #fff; /* / Color del texto del botón / */
      border: none;
      padding: 10px;
      cursor: pointer;
      /*margin-left: 200px;*/
      border-radius: 8px;
    }
    
    button:hover {
      background-color: #856505; /* / Color de fondo del botón al pasar el ratón  */
    }
  

    .btnC {
      background-color: #edb40b; /* / Color de fondo del botón / */
      color: #fff; /* / Color del texto del botón / */
      border: none;
      padding: 10px;
      cursor: pointer;
      margin-top: 50px;
      margin-bottom: 40px;
      margin-left: 200px;
      border-radius: 8px;
      font-size: 20px;
    }

    table {
      height: 400px;
    }

  