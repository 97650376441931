/* Hero.module.css */
.bioContainer {
  /*position: relative;*/
  height: 500px; /* Ajusta la altura según tus necesidades */
  max-width: 1500px; /* Ajusta el ancho máximo según tus necesidades */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  background-size: cover;
  background-position: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden; /* Para asegurar que la imagen no se desborde del contenedor */
  
}

.bioText {
  display:flex;
  flex-wrap: wrap;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  max-width: 100%; /* Limita el ancho del texto para evitar que ocupe demasiado espacio */
 /* filter: blur();*/
}

.bioText h2 {
  max-width: 360px;
  margin: 20px;
  padding: 10px;
  color: #fff;
  font-size: 20px;
}

.bioContainer::before {
  content: '';
  /*position: absolute;*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1); /* Un overlay oscuro para mejorar la legibilidad del texto */
  z-index: -1;
}

.bio {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.bio p{
  text-align:center;
  color: #333;
  font-size: 40px;

}