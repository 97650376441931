.content {
    display:flex;
    justify-content:space-around;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 10px;
}


.content a{
    
    display: flex;
    cursor:pointer;
    color:#fff;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
}

