.banner {
    padding: 10px;
    background-color:  #333;
    text-align: center;
    margin-top: 90px;
}
.caja {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin:10px;
  
}

.containText {
    align-items: center;
    /*justify-content: center;*/
    width: 700px; 
    margin: 20px;
}

.containText h1{
    text-align: center;
    color: #4caf50;
    font-size: 40px;
} 

.containText p {
    color: #edb40b;
    text-align: justify;
    font-size: 36px;
    
}

.caja img{
    width: 320px;
    max-height: 420px;
    /*border-radius: 8px;*/
    margin-top: 20px;
}

.botones button {
    background-color: #333;
    font-size: 30px;
    color:#fff;
    cursor:pointer;
    margin: 0 50px 20px;
    
}
.botones button:hover {
    background-color: black; 
    color:#fff;
    border-radius: 50%;
    height: 50px;
    
}

