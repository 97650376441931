.container{
  background-image: url('../../../assets/dron\ 39.jpg');
  background-repeat: no-repeat;
  background-size:100% 100%;
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 10px 100px;
}


.form__container {
  max-width: 400px;
  margin: 40px auto; 
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
  margin-top:120px;
  /*background-image: url('../../assets/dron\ 39.jpg');
  background-repeat: no-repeat;
  background-size:100% 100%;*/
}

.form {
  display: flex;
  flex-direction: column;
}

.form .close {
  margin-left: 300px;
  cursor: pointer;
  font-size: 20px;
 color:#333;
 background-color: #fff;
}
 .form .close:hover {
  border-radius: 50%;
  background-color:#ccc9c9;
 }

.s-form {
  margin-top: 20px;
}

fieldset {
  border: none;
}

legend {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}



label {
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form__container p {
  font-size: 0.8rem;
  color: #d9534f; /* / Color de error / */
  margin-top: 5px;
  margin-bottom: 15px;
}

span {
  font-size: 0.8rem;
  color: #777;
}

.btnC {
  background-color: #edb40b; /* / Color de fondo del botón / */
  color: #fff; /* / Color del texto del botón / */
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-left: 40%;
}

button:hover {
  background-color: #856505; /* / Color de fondo del botón al pasar el ratón  */
}
