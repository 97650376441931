.aservice {
    height: auto;
    align-items: center;
    /*justify-content: center;*/
    margin: 0  10% 0 10%;
    display: flex;
    flex-wrap: wrap;
    gap:50px;
    margin-top:90px;
}

.aimagenes {
   
    width: 400px;
}

.abig {
    width: 400px;
    height: 400px;
}

.alittles {
    display: flex;
    justify-content: space-between;
}
.alittles img {
    width: 32%;
    margin-top: 3px;
    cursor: pointer;
}
.atext {
    text-justify:auto;
    width: 660px;
    color: #333;
    background-image: url('../../../assets/pollo\ huevo.png');
    background-repeat: no-repeat;
    background-size:80% 80%;
    background-position:110% 80%;
}

.atext h1 {
    text-align: center;
    color: #856505;
}

.atext p {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.3;
    text-align: justify;
}

@media (max-width: 858px) {
    .aservice {
        margin: 2% 0 0 0;
    }
    .aimagenes{
      margin: auto;
    }
    .atext {
        margin: 0 5% 0 5%; 
        background-size:80% 50%;
        background-position:100% 50%;
    }

}