.service {
    height: auto;
    align-items: center;
    /*justify-content: center;*/
    margin: 0  10% 0 10%;
    display: flex;
    flex-wrap: wrap;
    gap:50px;
}

.imagenes {
   
    width: 400px;
}

.big {
    width: 400px;
}

.littles {
    display: flex;
    justify-content: space-between;
}
.littles img {
    width: 32%;
    margin-top: 3px;
    cursor: pointer;
}
.text {
    text-justify:auto;
    width: 660px;
    color: #333;
    background-image: url('../../../assets/Ecoworking/pollo\ gafas.png');
    background-repeat: no-repeat;
    background-size:80% 80%;
    background-position:110% 80%;
    
}

.text h1 {
    text-align: center;
    color: #20873f;
}

.text p {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.3;
    text-align: justify;
}


@media (max-width: 858px) {
    .service {
        margin: 2% 0 0 0;
    }
    .imagenes{
      margin: auto;
    }
    .text {
        margin: 0 5% 0 5%; 
        background-size:80% 50%;
        background-position:100% 50%;
    }
  
  }
