.containerlog {
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  width: auto;
  height: auto;
  /*min-height: 100%;*/
  padding: 100px 100px;
  margin-top: 90px;
  /*background: rgba(darken(#2196F3,40%), 0.85);*/
  background-image: url('../../assets/dron\ 39.jpg');
  background-repeat: no-repeat;
  background-size:100% 100%;
}




.login-container {
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  
  }

  .login-container h2 {
    color:#333;
    margin: 10px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-form label {
    margin-bottom: 10px;
  }
  
  .login-form input {
    width: 400px;
    padding: 10px;
    margin-bottom: -10px;
    border: 1px solid #baadad;
    border-radius: 4px;
  }
  
  .login-form button {
    background-color: #edb40b;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }

  .login-form button:hover {
    background-color: #856505;
  }
  
  .or__h3 {
    text-align: center;
    font-size: 18px;
    color: #555;
    margin: 10px 0;
  }
  
  .social-login-container {
    display: flex;
    justify-content: space-around;
  }
  
  .error__container{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    padding: 0;
    height: 40px;
  }

  .error {
    color: rgb(220, 3, 3);
    text-align: center;
    margin: 20px;
    font-size:12px;
  }

  @media (max-width: 858px) {
    .login-container {
        width: 380px;
    }
      .login-form input {
        width: 300px;
        
    }
  }
